import { PMREMGenerator} from './PMREMGenerator.js'
import {RGBELoader} from './RGBELoader.js'

const ENVIRONMENTS = {
  tankfarm: {
    hdri: 'abandoned_tank_farm_02',
    ground: 'ground-grass-rock',
    lights: [
      {position: "0.5 1 1", intensity: 1.6},
      {position: "0.5 0.3 0.1", intensity: 1.6}
    ],
    props: [
      {prop: 'rock', numObjects: 500, material: 'mossy_rock'},
    ]
  },
  sandstone: {
    hdri: 'the_sky_is_on_fire',
    ground: 'ground-sandstone',
    lights: [
      {position: "14.60351 3.91255 8.38254", intensity: 1.6},
      // {position: "18.33144 0.3 18.30444", intensity: 1.6}
    ],
    props: [
      {prop: 'stone', numObjects: 300, material: 'rock'},
    ]
  },
  interior: {
    hdri: "large_corridor",
    toneMapping: 2,
    ground: "ground-wood-floor",
    lights: [
      {position: "0 7.016 -3.91", intensity: 1.6}
    ],
    props: [
      {prop: "column", numObjects: 100, material: "planks", maxScale: 5, minScale: 3},
      {prop: "stone", numObjects: 100, material: "wood"}
    ]
  },
  winter: {
    hdri: "winter_lake_01",
    toneMapping: 3,
    ground: "ground-snow",
    lights: [
      {position: "0.5 1 1", intensity: 1.6, shadowRadius: 10},
      {position: "0.5 0.3 0.1", intensity: 1.6, shadowRadius: 10, shadowBias: 1.0}
    ],
    props: [{
      prop: 'rock',
      numObjects: 500,
      material: 'snow',
    }]
  },
  autumn: {
    hdri: "autumn_hockey",
    ground: "ground-forest",
    toneMapping: 2,
    lights: [
      {position: "0 7.016 0.91", intensity: 1.6, shadowRadius: 3}
    ],
    props: [
      {prop: 'rock', numObjects: 500, material: 'mossy_rock'},
    ]
  },
  empty_studio: {
    hdri: "colorful_studio",
  },
  empty_studio_floor: {
    hdri: "colorful_studio",
    ground: "ground-wood-floor",
  },
  night: {
    hdri: "dikhololo_night_edit",
    ground: "ground-forest",
    toneMapping: 2,
    lights: [
      {position: "0 3.2 0", intensity: 0.05, shadowRadius: 3, shadowBias: 0.1}
    ],
    props: [
      {prop: 'rock', numObjects: 100, material: 'metal'},
    ]
  },
}

AFRAME.registerSystem('enviropack', {
  schema: {
    baseUrl: {type: 'string', default: document.currentScript.src.split('/').slice(0, -1).join("/")}
  },
  init() {
    this.environments = ENVIRONMENTS;
    this.presets = ENVIRONMENTS;
    this.enviropack = null;
  },
  url(file) {
    if (!file) return null;
    if (!this.data) {
      console.warn("No data yet")
      return null;
    }
    let baseUrl = this.el.sceneEl.systems['enviropack'].data.baseUrl;
    return `${baseUrl}${baseUrl ? "/" : ""}${file}`
  },
})

AFRAME.registerComponent('enviropack', {
  schema: {
    preset: {type: 'string', oneOf: ENVIRONMENTS, default: "tankfarm"},
    baseUrl: {type: 'string', default: ""},
  },
  init() {
    this.lights = []
    this.props = []

    this.defaultLights = []

    if (this.system.enviropack)
    {
      // console.warn("There's already an existing enviropack. Expect problems")
      console.warn("Removing existing enviropack from other element", this.system.enviropack.el)
      this.system.enviropack.el.removeAttribute('enviropack')
    }
    this.system.enviropack = this
  },
  remove() {
    if (this.ground)
    {
      this.ground.remove()
    }

    for (let light of this.lights)
    {
      light.remove()
    }

    let sky = this.el.sceneEl.querySelector('a-sky');
    sky.removeAttribute('enviropack-hdri')

    for (let prop of this.props)
    {
      prop.remove()
    }

    this.el.sceneEl.systems.light.userDefinedLights = false
    this.el.sceneEl.systems.light.setupDefaultLights()

    if (this.system.enviropack === this)
    {
      this.system.enviropack = null
    }
  },
  update() {
    if (this.data.baseUrl)
    {
      this.system.data.baseUrl = this.data.baseUrl
    }

    let sceneEl = this.el.sceneEl;
    let sky = sceneEl.querySelector('a-sky');
    if (!sky) {
      sky = document.createElement('a-sky')
      sceneEl.append(sky)
    }

    let env = this.system.environments[this.data.preset]
    if (!env) {
      console.warn("No such preset", this.data.preset)
      return;
    }

    sky.setAttribute('enviropack-hdri', {hdri: env.hdri, backstop: env.backstop || "", toneMapping: env.toneMapping || 1})

    let ground
    if (!this.ground) {
      ground = document.createElement('a-plane')
      this.ground = ground
      this.el.append(ground)
      ground.setAttribute('position', '0 0 0')
      ground.setAttribute('rotation', "-90 0 0")
      ground.setAttribute('width', 100)
      ground.setAttribute('height', 100)
      ground.setAttribute('shadow', '')
    }
    else
    {
      ground = this.ground
    }

    if (env.ground)
    {
      ground.setAttribute('enviropack-material', {material: env.ground})
      ground.setAttribute('visible', true)
    }
    else
    {
      ground.setAttribute('visible', "false")
    }

    sceneEl.querySelectorAll('*[data-aframe-default-light]').forEach(el => {
      el.remove()
    })

    this.el.sceneEl.systems.light.userDefinedLights = true

    for (let light of this.lights)
    {
      light.remove()
    }
    this.lights.length = 0

    if (this.el.sceneEl.systems['enviropack-material'].chooseShader() !== 'pbmatcap')
    {
      for (let light of (env.lights || []))
      {
        let el = document.createElement('a-entity')
        this.el.append(el)
        this.lights.push(el)
        el.setAttribute('light', 'type', 'directional')
        el.setAttribute('light', 'castShadow', 'true')
        el.setAttribute('light', 'intensity', light.intensity)
        el.setAttribute('light', 'shadowRadius', light.shadowRadius || 1)
        el.setAttribute('light', 'shadowBias', light.shadowBias || 0.0)
        el.setAttribute('position', light.position)
        el.setAttribute('light', 'shadowCameraNear', -100)
        el.setAttribute('light', 'shadowCameraRight', 50)
        el.setAttribute('light', 'shadowCameraLeft', -50)
        el.setAttribute('light', 'shadowCameraTop', 50)
        el.setAttribute('light', 'shadowCameraBottom', -50)
      }
    }

    for (let prop of this.props)
    {
      prop.remove()
    }
    this.props.length = 0;

    for (let prop of (env.props || []))
    {
      let el = document.createElement('a-entity')
      this.el.append(el)
      this.props.push(el)
      el.setAttribute('enviropack-material', 'material', prop.material || 'rock')
      el.setAttribute('enviropack-prop', 'prop', prop.prop || 'stone')
      el.setAttribute('scatter-enviropack-props', {'numObjects': prop.numObjects || 5000,
                                                   'maxScale': prop.maxScale || 20,
                                                   'minScale': prop.minScale || 5})
    }

    this.el.sceneEl.querySelectorAll('*[enviropack-material]').forEach(el => {
      if (el.components && el.components.material && el.components.material.data.shader === 'pbmatcap')
      {
        el.components.material.shader.update(el.components.material.data)
      }
    })
  }
})

const HDRIS = {};
for (let fileName of require.context('./hdris/', true, /.*/).keys()) {
  let [dot, file] = fileName.split('/')
  let name = file.match(/(.*?)[-_]\d+k/i)[1]

  HDRIS[name] = require(`./hdris/${file}`)
}

const BACKSTOPS = {};

for (let fileName of require.context('./backstops/', true, /.*/).keys()) {
  let [dot, file] = fileName.split('/')
  let name = file.match(/(.*?)\.jpg/i)[1]

  BACKSTOPS[name] = require(`./backstops/${file}`)
}

AFRAME.registerSystem('enviropack-hdri', {
  init() {
    this.hdris = HDRIS;
  },
})

// Allows setting an HDRI to use as an a-sky background and scene-wide
// environment map
AFRAME.registerComponent('enviropack-hdri', {
  dependencies: ['material'],
  schema: {
    // Selector for the `a-asset-item` with the src set to the `.hdri` file
    hdri: {type: 'string', oneOf: HDRIS},

    backstop: {type: 'string'},

    // Exposure for the hdri
    exposure: {default: 1.0},

    // THREE.js tone mapping constant
    toneMapping: {default: 1},

    // If set, will set the envMap for all selected elements and children with compatible materials
    envMapSelector: {type: 'string', default: 'a-scene'},

    // Intensity of the environement map
    intensity: {default: 1.0},

    // If > 0 will set the envMap for all objects with compatible material continuously
    updateEnvMapThrottle: {default: 100},
  },
  init() {
    if (this.el.hasAttribute('material'))
    {
      this.originalMaterial = AFRAME.utils.extend({}, this.el.getAttribute('material'))
    }
  },
  remove() {
    this.el.removeAttribute('material')
    this.el.setAttribute('material', this.originalMaterial)

    for (let r of this.envMapSelectorElements)
    {
      r.object3D.traverseVisible(o => {
        if (o.material && o.material.type === 'MeshStandardMaterial' &&
          (o.material.envMap === this.envMap))
        {
          o.material.envMap = null
          o.material.needsUpdate = true
        }
      })
    }
  },
  update(oldData) {
    if (oldData.hdri !== this.data.hdri)
    {
      this.setHDRI()
    }

    if (oldData.envMapSelector !== this.data.envMapSelector)
    {
      this.envMapSelectorElements = Array.from(document.querySelectorAll(this.data.envMapSelector))
    }

    this.el.sceneEl.renderer.toneMapping = this.data.toneMapping
    this.el.sceneEl.renderer.toneMappingExposure = this.data.exposure

    if (oldData.updateEnvMapThrottle !== this.data.updateEnvMapThrottle)
    {
      if (this.data.updateEnvMapThrottle <= 0) {
        this.tick = function() {}
      }
      else
      {
        this.tick = AFRAME.utils.throttleTick(this._tick, this.data.updateEnvMapThrottle, this)
      }
    }
  },

  // Loads an RGBE (.hdr) image from URL, and returns a Promise resolving to a texture
  loadRGBE(url) {
    return new Promise((r, e) => {
      new RGBELoader()
  			.setDataType( THREE.HalfFloatType ) // alt: FloatType, HalfFloatType
  			.load( url , function ( texture, textureData ) {
          r({texture, textureData})
  			} );
      })
  },
  async setHDRI() {
    let url = this.el.sceneEl.systems.enviropack.url(this.system.hdris[this.data.hdri])
    let {texture} = await this.loadRGBE(url)
    let renderer = this.el.sceneEl.renderer
    renderer.toneMapping = this.data.toneMapping
    renderer.toneMappingExposure = this.data.exposure
    let wasXREnabled = renderer.xr.enabled
    renderer.xr.enabled = false
    let PMREMGeneratorClass = THREE.PMREMGenerator || PMREMGenerator
    var pmremGenerator = new PMREMGeneratorClass( renderer );
    pmremGenerator.compileEquirectangularShader();

    let skyEl = this.el
    let mesh = skyEl.getObject3D('mesh')
    mesh.material.color.set("#FFFFFF")

    if (this.data.backstop || this.data.hdri in BACKSTOPS)
    {
      skyEl.setAttribute('material', 'color', 'white')
      skyEl.setAttribute('material', 'src', '')
      skyEl.setAttribute('material', 'src', this.el.sceneEl.systems.enviropack.url(require(`./backstops/${this.data.backstop || this.data.hdri}.jpg`)))
      skyEl.components.material.material.toneMapped = false
      skyEl.components.material.material.needsUpdate = true
    }
    else
    {
      mesh.material.map = texture
      mesh.material.needsUpdate = true
      skyEl.components.material.material.toneMapped = true
      skyEl.components.material.material.needsUpdate = true
    }

    mesh.scale.x = -1
    mesh.scale.z = -1

    this.hdriTexture = texture
    var envMap = pmremGenerator.fromEquirectangular( texture ).texture;

    this.envMap = envMap
    renderer.xr.enabled = wasXREnabled

    pmremGenerator.dispose()

    this.setEnvMap()
  },
  setEnvMap() {
    if (!this.envMapSelectorElements) return
    for (let r of this.envMapSelectorElements)
    {
      r.object3D.traverseVisible(o => {
        if (o.material && o.material.type === 'MeshStandardMaterial' &&
          (o.material.envMap !== this.envMap || o.material.envMapIntensity !== this.data.envMapIntensity))
        {
          o.material.envMap = this.envMap
          o.material.envMapIntensity = this.data.intensity
          o.material.needsUpdate = true
        }
      })
    }
  },
  _tick() {
    this.setEnvMap()
  },
})
