const MATCAPS = {}

for (let fileName of require.context('./matcap/', true, /.*\.jpg/).keys()) {
  let [dot, folder, file] = fileName.split('/')
  // let name = folder.match(/(.*?)[-_]\d+k/i)[1]
  name = folder;
  if (!(name in MATCAPS))
  {
    MATCAPS[name] = {}
  }
  let matcap = file.match(/(.*?)\.jpg/)[1];
  MATCAPS[name][matcap] = require(`./matcap/${folder}/${file}`)
}

const MATCAP_TEXTURES = ["roughDialectric", "smoothDialectric", "roughMetallic", "smoothMetallic"];

AFRAME.registerShader('pbmatcap', {
  schema: {
    color: {type: 'color', is: 'uniform'},
    opacity: {type: 'number', is: 'uniform', default: 1.0},
    src: {type: 'map', is: 'uniform'},
    metalnessMap: {type: 'map', is: 'uniform'},
    roughnessMap: {type: 'map', is: 'uniform'},
    normalMap: {type: 'map', is: 'uniform'},
    normalScale: {type: 'vec2', is: 'uniform', default: new THREE.Vector2(1.0, 1.0)},
    ambientOcclusionMap: {type: 'map', is: 'uniform'},
    displacementMap: {type: 'map', is: 'uniform'},
    displacementScale: {type: 'number', is: 'uniform', default: 1.0},
    displacementBias: {type: 'number', is: 'uniform', default: 0.5},

    metalness: {type: 'number', is: 'uniform', default: 0.0},
    roughness: {type: 'number', is: 'uniform', default: 1.0},

    roughDialectric: {type: 'map', is: 'uniform'},
    smoothDialectric: {type: 'map', is: 'uniform'},
    roughMetallic: {type: 'map', is: 'uniform'},
    smoothMetallic: {type: 'map', is: 'uniform'},

    environment: {type: 'string'},

    // uv: {type: 'vec2', is: 'attribute'}
  },

  vertexShader: require('!!raw-loader!./matcap/vertexShader.glsl').default,
  fragmentShader: require('!!raw-loader!./matcap/fragmentShader.glsl').default,

  init(data) {
    this.attributes = this.initVariables(data, 'attribute');
    this.uniforms = this.initVariables(data, 'uniform');
    this.uniforms.uvTransform = { value: new THREE.Matrix3() };
    this.material = new (this.raw ? THREE.RawShaderMaterial : THREE.ShaderMaterial)({
      // attributes: this.attributes,
      uniforms: this.uniforms,
      vertexShader: this.vertexShader,
      fragmentShader: this.fragmentShader,
    });
    this.material.matcap = true
    this.material.normalMapType = 0
    this.autoMatcap = {}
    return this.material;
  },
  chooseMatcaps(data) {
    if (!data) return "default"
    if (data.environment) return data.environment;
    if (!this.el.sceneEl.systems.enviropack) return "default";
    if (!this.el.sceneEl.systems.enviropack.enviropack) return "default";
    if (this.el.sceneEl.systems.enviropack.enviropack.data.preset in MATCAPS) return this.el.sceneEl.systems.enviropack.enviropack.data.preset;
    return "default"
  },
  update(data) {
    let environment = this.chooseMatcaps(data)
    for (let matcapMap of MATCAP_TEXTURES)
    {
      if (data && (!data[matcapMap] || this.autoMatcap[matcapMap]))
      {
        data[matcapMap] = this.el.sceneEl.systems['enviropack'].url(MATCAPS[environment][matcapMap])
        this.autoMatcap[matcapMap] = true;
      }
    }
    this.updateVariables(data, 'attribute');
    this.updateVariables(data, 'uniform');

    if (this.material.map)
    {
      this.material.map.updateMatrix()
      this.material.uniforms.uvTransform.value.copy(this.material.map.matrix)
    }
  },
  setMapOnTextureLoad: function (variables, key, materialKey) {
    var self = this;
    this.el.addEventListener('materialtextureloaded', (e) => {
      if (key === 'src') {
        variables.map = variables.src
        key = 'map';
      }

      if (self.material[materialKey])
      {
        self.material[key] = self.material[materialKey];
        self.el.sceneEl.systems.renderer.applyColorCorrection(self.material[key])
        self.material.needsUpdate = true
      }
      variables[key].value = self.material[materialKey];
      variables[key].needsUpdate = true;

      if (this.material.map)
      {
        this.material.map.updateMatrix()
        this.material.uniforms.uvTransform.value.copy(this.material.map.matrix)
      }
    });
  }
});

class PBMatcap extends THREE.ShaderMaterial {
  constructor(parameters = {})
  {
    super(parameters)

    this.vertexShader = require('!!raw-loader!./matcap/vertexShader.glsl').default;
    this.fragmentShader = require('!!raw-loader!./matcap/fragmentShader.glsl').default;

    this.defines = { 'MATCAP': '' };
    this.type = 'PBMatcap'

    this.matcap = null
    this.normalMapType = 0

    this.roughness = 1.0;
    this.metalness = 0.0;

    this.color = new THREE.Color( 0xffffff );
    this.opacity = 1.0;
    this.map = null;
    this.normalMap = null;
    this.normalMapScale = null;
    this.ambientOcclusionMap = null;
    this.displacementMap = null;



    let loader = new THREE.TextureLoader()
    for (let map in MATCAPS.default)
    {
      this[map] = null;
      if (map in parameters) continue;
      this[map] = loader.load(MATCAPS.default[map], (t) => {
        this[map] = t
        this.needsUpdate = true
        t.needsUpdate = true
        this.setValues()
      })
    }

    this.setValues( parameters )
  }
  copy(source) {
    super.copy(source)
  }
  setValues(parameters) {
    if (parameters)
    {
      super.setValues(parameters)
    }

    this.uniforms = {
      roughness: {value: 1.0},
      metalness: {value: 0.0},
      smoothDialectric: {value: null, type: 't'},
      roughDialectric: {value: null, type: 't'},
      smoothMetallic: {value: null, type: 't'},
      roughMetallic: {value: null, type: 't'},
    }
    this.uniforms.roughness.value = this.roughness
    this.uniforms.metalness.value = this.metalness
    this.uniforms.smoothDialectric.value = this.smoothDialectric
    this.uniforms.roughDialectric.value = this.roughDialectric
    this.uniforms.smoothMetallic.value = this.smoothMetallic
    this.uniforms.roughMetallic.value = this.roughMetallic

    this.uniformsNeedUpdate = true
  }
}

PBMatcap.MATCAPS = MATCAPS;

THREE.PBMatcap = PBMatcap;
