const MAPS = ['map', 'roughnessMap', 'aoMap', 'normalMap', 'metalnessMap', "displacementMap"];
const ANISOTROPIC_MAPS = ['map', 'normalMap']

const MATERIALS = {
  "ground-grass-rock": {
    raw: 'aerial_grass_rock',
    repeat: 5,
    anisotropy: 16
  },
  "ground-sandstone": {
    raw: 'sandstone_cracks',
    repeat: 8,
    anisotropy: 16
  },
  "ground-wood-floor": {
    raw: "WoodFloor041",
    repeat: 32,
    anisotropy: 16,
  },
  "ground-snow": {
    raw: "Snow004",
    anisotropy: 16,
    repeat: 4
  },
  "ground-forest": {
    raw: "forrest_ground_03",
    anisotropy: 16,
    repeat: 16
  },
  "bark": {
    raw: "bark_brown_02",
    anisotropy: 4,
    displacementScale: 0.2,
    displacementBias: 0.0,
  },
  "gold": {
    raw: "Metal035",
  },
  "metal": {
    raw: "Metal009"
  },
  "plastic": {
    raw: "Plastic",
    repeat: 3,
    anisotropy: 2
  },
  "fabric": {
    raw: "Fabric026",
    repeat: 2,
    anisotropy: 8,
  },
  "concrete": {
    raw: "dirty_concrete",
    repeat: 2,
    anisotropy: 4
  },
  "rock": {
    raw: "rock_05",
    displacementScale: 0.1,
    displacementBias: 0.0,
    repeat: 1
  },
  "mossy_rock": {
    raw: "mossy_rock",
    anisotropy: 4,
  },
  "wood": {
    raw: "Wood027",
    repeat: 1.5,
    anisotropy: 4,
  },
  "planks": {
    raw: "brown_planks_04",
    repeat: 2,
    anisotropy: 4
  },
  "snow": {
    raw: "Snow003",
    displacementScale: 0.3,
    displacementBias: 0.0,
  }
}

const MAP_FROM_FILENAME = {
  'aoMap': [/AmbientOcclusion(Map)?/i, /(\b|_)AO(map)?(\b|_)/i],
  'displacementMap': [/(\b|_)Disp(lacement)?(Map)?(\b|_)/i],
  'normalMap': [/(\b|_)norm?(al)?(map)?(\b|_)/i],
  'emissiveMap': [/(\b|_)emi(t|tion|ssive|ss)?(map)?(\b|_)/i],
  'metalnessMap': [/(\b|_)metal(ness|l?ic)?(map)?(\b|_)/i],
  'roughnessMap': [/(\b|_)rough(ness)?(map)?(\b|_)/i],
  'src': [/(\b|_)diff(use)?(\b|_)/i, /(\b|_)col(or)?(\b|_)/i],
}

function mapFromFilename(filename) {
  for (let map in MAP_FROM_FILENAME)
  {
    if (MAP_FROM_FILENAME[map].some(exp => exp.test(filename)))
    {
      return map
    }
  }
}

const ALL_MATERIALS = {};

for (let fileName of require.context('./materials/', true, /.*/).keys()) {
  let [dot, folder, file] = fileName.split('/')
  let name = folder.match(/(.*?)[-_]\d+k/i)[1]
  if (!(name in ALL_MATERIALS))
  {
    ALL_MATERIALS[name] = {}
  }
  ALL_MATERIALS[name][mapFromFilename(file)] = require(`./materials/${folder}/${file}`)
}

for (let [name, data] of Object.entries(MATERIALS)) {
  ALL_MATERIALS[name] = Object.assign({}, data, ALL_MATERIALS[data.raw] || {})
}

AFRAME.registerSystem('enviropack-material', {
  schema: {
    autoApply: {default: true},
    shader: {default: 'auto'},
  },
  init() {
    this.materials = ALL_MATERIALS;
  },
  url(file) {
    if (!file) return null;
    if (!this.data) {
      console.warn("No data yet")
      return null;
    }
    let baseUrl = this.el.sceneEl.systems['enviropack'].data.baseUrl;
    return `${baseUrl}${baseUrl ? "/" : ""}${file}`
  },
  chooseShader() {
    if (this.data.shader !== 'auto') return this.data.shader;
    if (AFRAME.utils.device.isMobile()) return 'pbmatcap';
    if (AFRAME.utils.device.isMobileVR()) return 'pbmatcap';
    return 'standard'
  },
  forceShaderChange(shader) {
    this.data.shader = shader;
    this.el.querySelectorAll('*[enviropack-material]').forEach(el => {
      if (el.components['enviropack-material'].data.shader === 'auto')
      {
        el.components['enviropack-material'].forceUpdate()
      }
    })
  }
})

AFRAME.registerComponent('enviropack-material', {
  schema: {
    material: {default: "ground-grass-rock", oneOf: Object.keys(MATERIALS)},
    displacementMap: {default: false},
    shader: {default: 'auto'}
  },
  events: {
    materialtextureloaded: function (e) {
      this.setRepeat(this.repeat)
      this.setAnisotropy(this.anisotropy)
    },
    object3dset: function(e) {
      this.applyMaterial()
    },
    componentchanged: function(e) {
      if (e.detail === 'material')
      {
        this.applyMaterial()
      }
    }
  },
  update(oldData) {
    if (this.data.material !== oldData.material) {
      this.forceUpdate()
    }
  },
  forceUpdate() {
    let material = this.system.materials[this.data.material]
    if (!material) {
      console.warn("No such material", this.data.material)
      return
    }

    let shader = this.data.shader === 'auto' ? this.chooseShader() : this.data.shader;
    this.el.setAttribute('material', 'shader', shader)
    this.el.setAttribute('material', 'src', this.system.url(material.src))
    this.el.setAttribute('material', 'normalMap', this.system.url(material.normalMap))
    this.el.setAttribute('material', 'ambientOcclusionMap', this.system.url(material.aoMap))
    this.el.setAttribute('material', 'roughnessMap', this.system.url(material.roughnessMap))
    this.el.setAttribute('material', 'metalnessMap', this.system.url(material.metalnessMap))
    this.el.setAttribute('material', 'displacementMap', this.data.displacementMap ? this.system.url(material.displacementMap) : null)
    this.el.setAttribute('material', 'roughness', material.roughnessMap ? 1.0 : (material.roughness || 0.0))
    this.el.setAttribute('material', 'metalness', material.metalnessMap ? 1.0 : (material.metalness || 0.0))
    if (shader === 'pbmatcap')
    {
      this.el.setAttribute('material', 'displacementScale', material.displacementScale || 1.0)
      this.el.setAttribute('material', 'displacementBias', ("displacementBias" in material) ? material.displacementBias : 0.5)
    }
    else
    {
      this.el.components.material.material.displacementScale = material.displacementScale || 1.0
      this.el.components.material.material.displacementBias = ("displacementBias" in material) ? material.displacementBias : 0.5;
    }
    this.setRepeat(material.repeat || 1)
    this.setAnisotropy(material.anisotropy || 1)
  },
  chooseShader() {
    if (this.data.shader !== 'auto') return this.data.shader;

    return this.system.chooseShader();
  },
  setRepeat(scale) {
    this.repeat = scale
    let materialComponent = this.el.components.material
    let material = materialComponent.material
    for (let map of MAPS) {
      if (!material[map]) continue;
      material[map].repeat.set(scale, scale)
      material[map].wrapT = THREE.RepeatWrapping
      material[map].wrapS = THREE.RepeatWrapping
      material[map].needsUpdate = true
    }

    if (materialComponent.data.shader === 'pbmatcap')
    {
      materialComponent.shader.update(materialComponent.shader.data)
    }
  },
  setAnisotropy(anisotropy) {
    let material = this.el.components.material.material

    if (AFRAME.utils.device.isMobileVR())
    {
      anisotropy = 1;

      for (let map of MAPS)
      {
        if (material[map])
        {
          material[map].magFilter = THREE.NearestFilter
          material[map].minFilter = THREE.LinearMipmapNearestFilter
          material[map].needsUpdate = true
        }
      }
    }

    this.anisotropy = anisotropy;
    for (let map of ANISOTROPIC_MAPS)
    {
        if (!material[map]) continue;

        material[map].anisotropy = anisotropy;
        material[map].needsUpdate = true;
    }
  },
  applyMaterial(mesh = undefined)
  {
    if (!this.el.hasAttribute('material')) return
    if (!mesh) mesh = this.el.getObject3D('mesh')
    if (!mesh) return


    let material = this.el.components.material.material

    mesh.traverse(o => {
      if (o.material)
      {
        o.material = material
      }
    })
    this.el.emit('enviropack-material-applied', material)
  }
})
