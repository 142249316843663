const PROPS = {}
for (let fileName of require.context('./models/', true, /.*/).keys()) {
  let [dot, file] = fileName.split('/')
  let name = file.match(/(.*?)\.glb/i)[1]

  PROPS[name] = require(`./models/${file}`)
}

if (AFRAME.utils.device.isMobileVR())
{
  PROPS.rock = PROPS.stone
}

AFRAME.registerComponent('enviropack-prop', {
  schema: {
    prop: {type: 'string', oneOf: PROPS},
  },
  update(oldData) {
    if (!(this.data.prop in PROPS))
    {
      console.warn("No such prop", this.data.prop)
      return;
    }
    this.el.setAttribute('gltf-model', this.el.sceneEl.systems.enviropack.url(PROPS[this.data.prop]))
  }
})

AFRAME.registerComponent('scatter-enviropack-props', {
  schema: {
    innerRadius: {default: 30},
    outerRadius: {default: 50},
    numObjects: {default: 5000},
    maxScale: { default: 20},
    minScale: {default: 0.5 * 10},
  },
  events: {
    object3dset: function(e) {
      this.el.getObject3D('mesh').visible = false
      this.scatter();
    },
    'enviropack-material-applied': function(e) {
      this.scatter();
    },
    materialtextureloaded: function(e) {
      this.scatter();
    },
    componentchanged: function(e) {
      if (e.detail === 'material')
      {
        this.scatter();
      }
    }
  },
  update(oldData) {
    this.scatter();
  },
  init() {
  },
  remove() {
    if (this.instancedMesh)
    {
      this.instancedMesh.parent.remove(this.instancedMesh)
      this.instancedMesh.dispose()
    }
  },
  scatter() {
    if (!this.el.getObject3D('mesh')) return;
    let sourceMesh = this.el.getObject3D('mesh').getObjectByProperty('type', 'Mesh')
    if (!sourceMesh) return;
    // if (this.el.components.material.material.type !== 'MeshStandardMaterial') return;
    if (this.instancedMesh)
    {
      this.instancedMesh.parent.remove(this.instancedMesh)
      this.instancedMesh.dispose()
    }

    let numObjects = this.data.numObjects;

    if (AFRAME.utils.device.isMobile() || AFRAME.utils.device.isMobileVR())
    {
      numObjects = Math.min(100, numObjects)
    }

    let instancedMesh = new THREE.InstancedMesh(sourceMesh.geometry, this.el.components.material.material, numObjects)
    // Old a-frame compatibility
    if (!instancedMesh.dispose) {
      instancedMesh.dispose = function() {};
    }
    let matrix = new THREE.Matrix4();
    let pos = new THREE.Vector3();
    let scale = new THREE.Vector3();
    let rot = new THREE.Euler();
    let quat = new THREE.Quaternion()

    for (let i = 0; i < numObjects; ++i)
    {
      matrix.identity()
      pos.setFromSphericalCoords(
        THREE.MathUtils.lerp(this.data.innerRadius, this.data.outerRadius, Math.random()),
        Math.random() * Math.PI * 2,
        0
      );
      pos.x = pos.y;
      pos.y = 0;
      scale.set(1,1,1)
      scale.multiplyScalar(THREE.MathUtils.lerp(this.data.minScale, this.data.maxScale, Math.random()));
      rot.y = Math.random() * Math.PI * 2;
      quat.setFromEuler(rot)
      matrix.compose(pos, quat, scale)
      instancedMesh.setMatrixAt(i, matrix)
      // instancedMesh.instanceMatrix.needsUpdate = true
      // e.object3D.rotation.y = Math.random() * Math.PI * 2;
    }
    this.el.object3D.add(instancedMesh)
    this.instancedMesh = instancedMesh
  }
})
